
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.container {
  border-radius: 8px;
  border: 1px solid color.$gray-gainsboro;
  margin-bottom: 20px;
  width: 270px;
  margin-right: 30px;
  display: none;

  @include breakpoint('large') {
    display: block;
  }
}

.subContainer {
  background-color: color.$heading-bg;
  padding: 15px 20px;

  a {
    color: color.$black-color;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  .icon-space:hover {
    text-decoration: none;
  }

  .removeUnderline {
    &:hover {
      text-decoration: none;
      cursor: default;
    }
  }

  li.list {
    margin-top: 7px;
  }
}

.content-item {
  margin-bottom: 9px;
}

.accordion-box {
  display: flex;
}

.narrow {
  .checkboxlabel {
    margin-left: 27px;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 20px 14px;
  margin: 0;
  border-bottom: 2px solid color.$red-neon;
  letter-spacing: -0.015em;
}

.listheading {
  margin-bottom: 8px;
}

.checkbox-flex {
  display: flex;
  margin-bottom: 0px;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.list {
  list-style-type: none;
  padding: 0;
  margin-top: 0px;
  font-size: 13px;
  padding-bottom: 15px;

  @include breakpoint('large') {
    padding-bottom: 0;
  }

  li {
    padding-bottom: 20px;

    @include breakpoint('large') {
      padding-bottom: 0px;
    }
  }

  .checkboxlabel,
  a {
    color: color.$black-color;
    cursor: pointer;
  }

  strong {
    font-weight: typography.$bold;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .checkboxlast {
    color: color.$red-neon;

    &:hover {
      text-decoration: underline;
    }

    .checkboxlabel {
      color: color.$red-neon;
    }
  }
}

.item {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 8px;
  width: 17px;
  height: 17px;
}

.link,
.checkboxlabel {
  text-decoration: none;
  margin-left: 0px;
  font-size: 13px;
  line-height: 21px;

  @include breakpoint('medium') {
    font-size: 12px;
    line-height: 19px;
  }

  @include breakpoint('large') {
    font-size: 13px;
    line-height: 21px;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.checkbox-flex-srp {
  .link {
    @include breakpoint('large') {
      font-size: 13px;
    }
  }
}

.list-srp {
  .checkboxlabel {
    @include breakpoint('large') {
      font-size: 13px;
    }
  }
}

.subbox {
  .link {
    margin-left: 0;

    @include breakpoint('large') {
      margin-top: 7px;
      display: inline-block;
    }
  }
}

.checkboxlabel {
  margin-left: 27px;

  @include breakpoint('large') {
    margin-left: 20px;
    position: relative;
    top: -5px;
  }
}

.list-srp {
  .checkboxlabel {
    @include breakpoint('large') {
      top: -2px;
      font-size: 13px;
      margin-left: 16px;
    }
  }
}

.linkbox-srp {
  .checkboxlabel {
    @include breakpoint('large') {
      font-size: 13px;
    }
  }
}

.icn-chevron-dwn::before {
  content: '\e913';
}

.linkbox {
  display: flex;
  gap: 8px;

  @include breakpoint('large') {
    margin-bottom: 7px;
  }

  label {
    padding-left: 0;
  }
}

.selectstore-desktop {
  padding-left: 28px;
  margin-top: -18px;
  cursor: pointer;

  p {
    color: color.$gray2;
  }
}

.disabled {
  margin-bottom: 6px;
  display: flex;

  span {
    cursor: default;
  }
}

.subbox {
  padding-left: 20px;
  margin-top: 20px;

  @include breakpoint('large') {
    padding-left: 38px;
    margin-top: 0px;
  }
}

.action {
  color: color.$celtic-blue;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.sublist {
  font-size: 13px;
  line-height: 21px;
  cursor: pointer;
  margin-bottom: 7px;
}

.viewButton {
  background: 0 0 !important;
  color: color.$primary-color;
  border: none;
  cursor: pointer;
  outline: 0;
  padding: 0;
  font-weight: typography.$light;
  display: flex;
  gap: 4px;
  align-items: baseline;
}

.mobContainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .last {
    padding-left: 4px;
  }

  .bold {
    font-weight: 400;
  }

  .twocolumn {
    li {
      overflow: hidden;
      display: inline-block;
      padding-right: 0;
      vertical-align: top;

      @include breakpoint('large') {
        margin-bottom: 10px;
      }
    }
  }

  .title {
    border: none;
    padding: 15px 0px;
    position: relative;
    font-size: 17px;
    margin-bottom: 3px;
    flex: 1;
    font-weight: 600;
  }

  .subheading {
    display: block;
    color: color.$gray;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
    margin-top: 5px;
    font-weight: typography.$medium;
  }

  .subbox {
    padding-left: 10px;
  }

  .filter-sub-container {
    .checkboxlabel {
      margin-left: 0;
    }
  }

  .list a {
    b {
      position: relative;
      display: block;
    }
  }

  .linkbox {
    .checkboxlabel {
      margin-left: 20px;
    }
  }

  @include breakpoint('large') {
    display: none;
  }
}

.narrow-disable {
  color: color.$black-color;
  display: flex;
  gap: 9px;

  div {
    opacity: 0.5;
  }
}

.mobArrow {
  position: absolute;
  right: -8px;
  background: url('/assets/images/Hamburger-Arrow-Right.svg') no-repeat;
  height: 14px;
  width: 14px;
  background-size: contain;
}

.link {
  .mobArrow {
    right: 14px;
    margin-top: 4px;
  }
}

.icon-space {
  margin-right: 8px;
  font-size: 10px;

  a:hover {
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.subcategory-list-srp {
  .icon-space {
    margin-top: -3px;
  }

  .icon-space-minus {
    margin-top: 0px;
  }

  .link {
    @include breakpoint('large') {
      font-size: 13px;
    }
  }
}

.filter-sub-container {
  position: absolute;
  background: color.$white-color;
  height: 100%;
  z-index: 1;
  width: 100%;
  left: 0;
  padding: 0px 20px 47px 20px;
  top: 0;

  .list {
    overflow: auto;
    height: 80%;
    margin-right: -15px;
    max-height: 100vh;
    padding-bottom: 66px;
    width: 100%;
  }

  .title {
    text-align: center;

    .left-arrow {
      position: absolute;
      left: 2px;
      transform: rotate(-180deg);
      top: 32px;
    }
  }
}

.nav-mobile {
  display: none;

  @include breakpoint('large') {
    display: block;
  }

  .linkbox {
    margin-bottom: 8px;
  }
}

.filter-guided-nav {
  background-color: color.$white-color;
  transition: transform 0.5s ease-out;
  position: fixed;
  width: 300px;
  top: 0;
  right: -300px;
  bottom: 0;
  z-index: 1100;
  display: block;

  &.active {
    transform: translate(-300px, 0);
    box-shadow: 3px 0 10px color.$black3;
  }
}

@include breakpoint('large') {
  .filter-guided-nav {
    display: none;
  }
}

.filter-title {
  padding: 20px;
  letter-spacing: -0.015em;
  font-size: 22px;
  position: relative;
  line-height: 29px;
  padding-bottom: 0;
  margin: 0;
}

.hr-divider {
  border-top: 1px solid color.$light-gray;
  margin: 20px 0;
  margin: 20px 0 6px;
  opacity: 1;

  @include breakpoint('large') {
    margin: 20px 0;
  }
}

.mb0 {
  margin-bottom: 0;
}

.mob-buttons {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  box-shadow: 10px 2px 10px color.$thin-gray;
  gap: 15px;
  width: 100%;
  z-index: 1;
  background: color.$white-color;

  .mob-button {
    font-size: 16px;
    padding: 15px 10px;
    border: none;
    color: color.$white-color;
    border-radius: 50px;

    &.clear {
      background: color.$gray;
    }

    &.view {
      background: color.$primary-color;
    }
  }

  .filter-title {
    padding: 20px;
    letter-spacing: -0.015em;
    font-size: 22px;
    position: relative;
    line-height: 29px;
    padding-bottom: 0;
    margin: 0;
  }

  .hr-divider {
    border: 1px solid color.$light-gray;
    margin: 20px 0;
  }
}

.twocolumn {
  column-count: 2;
  column-gap: 10px;

  li {
    overflow: hidden;
    display: inline-block;
    padding-right: 0;
    vertical-align: top;

    @include breakpoint('large') {
      margin-bottom: 10px;
    }
  }
}

.filter-text {
  &.active {
    &::before {
      content: '';
      height: 100%;
      background: color.$bgmodal;
      position: fixed;
      left: 0;
      width: 100%;
      top: 0;
    }
  }

  position: absolute;
  right: 0;
  color: color.$primary-color;
  margin-top: 5px;
  top: 0;
  z-index: 1;

  @include breakpoint('large') {
    display: none;
  }
}

.mobileToggle {
  width: 45px;
  border-radius: 20px;
  height: 30px;
  border: 1px solid color.$grey8;
  background-color: color.$grey9;
  border-color: color.$grey8;
  box-shadow: color.$grey8 0 0 0 0 inset;
  transition:
    border 0.4s ease 0s,
    box-shadow 0.4s ease 0s;
  display: inline-block;
  vertical-align: -10px;
  margin-right: 9px;
  cursor: pointer;
  position: relative;

  &:before {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: color.$white-color;
    content: ' ';
    top: 1px;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  }

  &.active {
    background-color: color.$blue-toggle;
    border-color: color.$blue-toggle;
    box-shadow: color.$blue-toggle 0 0 0 8px inset;
    transition:
      border 0.4s ease 0s,
      box-shadow 0.4s ease 0s,
      background-color 1.2s ease 0s;

    &:before {
      right: 0;
      left: unset;
    }
  }
}

.toggle-container {
  &:empty {
    display: none;
  }

  @include breakpoint('large') {
    display: none;
  }

  p {
    color: color.$gray2;
    padding-left: 56px;
    font-size: 12px;
  }
}

.toggle-container-wrapper {
  padding-bottom: 20px;
  margin-bottom: 0px;

  @include breakpoint('large') {
    padding-bottom: 0;
  }
}

.primary-color {
  color: color.$primary-color !important;

  &:hover {
    text-decoration: underline;
  }
}

.selectstore {
  margin-left: 55px;
  cursor: pointer;
  display: inline-block;
}

.scroll-container {
  padding-bottom: 190px;
  height: calc(100% - 100px);
  position: relative;
  overflow: auto;
}

.no-scroll {
  .scroll-container {
    overflow: hidden;
  }
}

.SkeletonUI {
  margin-bottom: 20px;
}

.labelBold {
  font-weight: 700;
}

.pointer {
  cursor: pointer;
}

.nonExpend {
  margin-left: 20px;
}

.twoColumnWrapper {
  overflow: auto;
  height: 100vh;
  width: 100%;
  padding-bottom: 260px;

  .leftSideColumn,
  .rightSideColumn {
    height: min-content;
  }

  .list {
    overflow: initial;
    max-height: 100%;
    padding-bottom: 0;
    margin: 0;
  }
}

.leftSideColumn,
.rightSideColumn {
  width: 50%;
}

.leftSideColumn {
  &.list-srp {
    @include breakpoint('medium') {
      padding-right: 15px;
    }
  }
}

.rightSideColumn {
  &.list-srp {
    @include breakpoint('medium') {
      padding-left: 15px;
    }
  }
}

.bold {
  font-weight: 600;
}

.active-clear .filter-sub-container .list {
  padding-bottom: 125px;
}

.store-pickup {
  .checkboxlabel {
    margin-left: 27px;
    position: relative;
    top: -4px;
  }
}
