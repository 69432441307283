
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.radioButtonFieldControl {
  position: relative;

  label {
    display: flex;
    align-items: center;
  }

  .radioButtonLabel {
    line-height: 22px;
    letter-spacing: -0.015em;
    font-size: 14px;
    color: color.$black-color;
  }

  .radio-button-indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid color.$thin-gray;
    background: color.$white-color;
    cursor: pointer;
    border-radius: 100%;
    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      top: 6px;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: color.$white-color;
    }
  }

  .radio {
    visibility: visible;
    margin: 0 4px 0 0;
    width: 18px;
    height: 18px;
    top: 0;
    opacity: 0;
    &:checked {
      margin: 0 4px 0 0;
      width: 18px;
      height: 18px;
      & ~ .radio-button-indicator {
        background: color.$green-blue;

        border: none;
        &::after {
          display: block;
        }
      }
    }
  }
}
