
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.addRegistryModal-content-wrp {
  padding-top: 10px;
  padding-bottom: 5px;
  height: auto;
  max-height: 62vh;
  overflow-y: auto;
  margin-right: -1rem;
  padding-right: 1rem;
  .add-registry-item-wrapper {
    ul {
      li {
        padding: 10px 0;
        border-bottom: 1px solid color.$gray-gainsboro;
        &:first-child {
          padding-top: 0;
        }
        .add-registry-radio {
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .add-more-item {
    padding-bottom: 15px;
    padding-top: 10px;
    a {
      color: color.$primary-color;
      .icn-plus-lgt-otl {
        font-size: 22px;
        margin-right: 5px;
        vertical-align: text-bottom;
        &::before {
          content: '\e93f';
        }
      }
    }
  }
  .action-btn {
    padding: 7px 32px;
    font-size: 16px;
    line-height: 27px;
    @include breakpoint('medium') {
      padding: 5px 22px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
