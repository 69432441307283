
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.store-selector {
  display: flex;
  flex-direction: column;
  padding: 20px 0 10px;
  @include breakpoint('medium') {
    padding: 10px 0;
  }
}

.selected-store,
.select-store {
  color: color.$primary-color;
  font-size: 14.45px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  @include breakpoint('medium') {
    font-size: 13px;
  }
}

.selected-store {
  margin-left: 5px;
}
.ship-item,
.store-selector {
  .disabled-text {
    color: color.$gray-silver;
  }
}

.assistive-text {
  display: inline-block;
  font-size: 14.45px;
  letter-spacing: -0.21px;
  line-height: 18px;
  margin-left: 25px;
  @include breakpoint('medium') {
    font-size: 11.9px;
  }
}

.ship-item {
  padding: 0px 0;
  .pdp-font-17 {
    font-size: 17px;
    line-height: 27px;
    @include breakpoint('medium') {
      font-size: 14px;
    }
  }
}
.ship-item-radio {
  span {
    padding-left: 4px;
  }
}

.info-button {
  display: inline-block;
  cursor: pointer;
}
.icn-help-otl {
  cursor: pointer;
  margin-left: 5px;
  &::before {
    content: '\e927';
  }
}

.bopis-icon {
  display: flex;
  align-items: center;
}

.instore-icon {
  background: variables.$background-lakeshore-sprites -140px 0/200px 200px;
  display: inline-block;
  height: 20px;
  width: 20px;
}
.plus-icon {
  background: variables.$background-lakeshore-sprites -64px 0/80px 80px;
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0 5px;
  text-align: center;
  align-items: center;
}
.curbside-icon {
  background: variables.$background-lakeshore-sprites -225px -2px/250px 250px;
  display: inline-block;
  height: 20px;
  width: 25px;
  text-align: center;
}

.store-pickup-wrapper {
  display: flex;
  gap: 5px;
  width: 380px;
  .disabled-radio-button {
    background: color.$heading-bg;
    cursor: not-allowed;
  }
}
.inStock-wrapper {
  display: flex;
  margin-left: 25px;
  font-size: 14.45px;
  @include breakpoint('medium') {
    font-size: 11.9px;
  }
}
.store-pickup-label {
  margin-left: -1px;
  margin-bottom: 6px;
}
.store-pickupType-wrapper {
  display: flex;
  margin-left: 25px;
  height: 20px;
  padding: 0;
}

.bold {
  font-weight: typography.$bold;
}

.ship-item {
  .disabled-radio-button {
    background: color.$heading-bg;
    cursor: not-allowed;
  }
}
.shipment-wrapper {
  display: flex;
  flex-direction: column;
}

.select-nearest-store {
  text-decoration: underline;
  cursor: pointer;
  color: color.$black;
}
.bopis-section-wrapper {
  margin: 20px 0;
  padding-bottom: 10px;
  padding-top: 6px;
  border-top: 1px solid color.$light-gray;
  border-bottom: 1px solid color.$light-gray;
}

.alert-text {
  color: color.$red-neon;
}

.pdp-change-store-modal {
  max-width: 400px;
}

.change-store-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .cancel-store {
    margin-right: 20px;
  }
  .change-store {
    padding: 5px;
  }
}

.select-store-error-msg {
  color: color.$red-neon;
  margin: 15px 0px -5px 0px;
}
