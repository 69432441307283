
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.checkBoxWrapper {
  margin-bottom: 10px;
  position: relative;
  label {
    margin: 0;
  }
}

.passwordConditionsWrapperCreate {
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
  opacity: 0;
  &.open {
    max-height: 400px;
    opacity: 1;
    overflow: visible;
  }
  &.collapsed {
    opacity: 0;
  }
  ul {
    list-style: none;
    margin: 0 0 15px;
    padding: 0;

    li {
      font-size: 12px;
      display: flex;
      span {
        margin-right: 5px;
      }
    }
  }
}

.rightSection {
  position: relative;
  ul {
    li {
      list-style: none;
      padding: 7px 0 12px 100px;
      position: relative;
      margin-bottom: 30px;
    }
  }
  .rewards-heading {
    font-size: 14px;
    color: color.$ternary-btn-color;
    line-height: 16px;
    font-weight: typography.$medium;
  }
}

.popOverCreateStaySigned {
  font-size: 14px;
  padding: 7px;
  border: 1px solid color.$light-gray;
  border-radius: 5px;
  width: 100%;
  position: absolute;
  text-align: center;
  left: 0px;
  z-index: 11;
  background: color.$white-color;
  line-height: 22px;
  top: -145px;
  box-shadow: 1px 6px 10px #00000040;
  color: color.$black-color;
  &::after {
    width: 0;
    height: 0;
    content: '';
    bottom: -19px;
    position: absolute;
    left: 45%;
    border-top: 10px solid color.$white-color;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}

.popOverStaySigned {
  font-size: 11px;
  padding: 7px 10px;
  border: 1px solid color.$light-gray;
  border-radius: 5px;
  width: 315px;
  position: absolute;
  left: -10px;
  text-align: left;
  z-index: 11;
  background: color.$white-color;
  line-height: 16px;
  top: -75px;
  box-shadow: 1px 6px 10px #00000040;
  &::after {
    width: 0;
    height: 0;
    content: '';
    bottom: -19px;
    position: absolute;
    left: 45%;
    border-top: 10px solid color.$white-color;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    color: color.$black-color;
  }
}
.password-icon {
  &.showIcon {
    background: variables.$background-lakeshore-sprites -306px -34px / 340px
      340px;
  }
  &.hideIcon {
    background: variables.$background-lakeshore-sprites -272px -34px/340px 340px;
  }
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 30px;
}
.staySignPopOverText {
  cursor: pointer;
}
.inputPassword {
  position: relative;
  input {
    font-weight: typography.$light;
  }
}
.inputFocusWrap {
  position: relative;
}

.icnHelpOtl {
  margin-left: 5px;
  color: color.$black-color;
  &::before {
    content: '\e927';
  }
}
.error-msg {
  color: color.$red;
  font-size: 14px;
  margin: -10px 0 10px;
  &.domain {
    background-color: #f0f0f1;
    padding: 15px;
  }
  a {
    color: color.$primary-color;
    text-decoration: underline;
  }
}

.leftSection {
  border-right: 1px solid color.$gray5;
  padding: 0 20px 0;
}

.rightSection {
  position: relative;
  ul {
    li {
      list-style: none;
      padding: 7px 0 12px 100px;
      margin-bottom: 35px;
    }
  }
  .rewards-heading {
    font-size: 14px;
    color: color.$ternary-btn-color;
    line-height: 16px;
    font-weight: typography.$medium;
  }
}

.modal-content {
  h4 {
    font-size: 22px;
    margin-bottom: 0;
  }
}
.modalDialog {
  max-width: 733px;
  margin-left: auto;
  margin-right: auto;
}
.modalDialogWrapper {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  display: block;
  font-weight: typography.$bold;
  color: color.$blue;
  font-size: 18px;
}

.subtitle {
  display: block;
  font-weight: typography.$medium;
  font-size: 12px;
  color: color.$ternary-btn-color;
  line-height: 100%;
}

.icon::before {
  left: 16px;
  top: 7px;
  content: '';
  position: absolute;
  width: 57px;
  height: 57px;
  background: variables.$background-lakeshore-sprites 0 -114px / 570px 570px;
}

.icon {
  &.redem {
    &::before {
      background: variables.$background-lakeshore-sprites -60px -120px / 600px
        600px;
    }
  }
}

.icon {
  &.save {
    &::before {
      background: variables.$background-lakeshore-sprites -110px -110px / 550px
        550px;
    }
  }
}

.icon::after {
  background: 0 0;
  border-radius: 50%;
  content: '';
  border: 1px solid color.$blue-royal;
  right: auto;
  left: 6px;
  width: 78px;
  position: absolute;
  height: 78px;
  top: 0;
}

.inputWrapper {
  flex: 1;
  input {
    font-weight: typography.$light;
  }
}

.signInBottom {
  position: absolute;
  bottom: 10px;
  color: color.$black-color;
  font-size: 11px;
  font-weight: typography.$medium;
  a {
    color: color.$primary-color;
    &:hover {
      text-decoration: underline;
    }
  }
}

.publicwifiText {
  font-size: 11px;
}
.gsa-user {
  font-size: 13px;
  color: color.$primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.close-icon {
  background: url(/assets/images/typeahead-x.png) no-repeat right top;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
}

.button-wrap {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  > div {
    flex: 1;
    width: 100%;
  }
  @include breakpoint('medium') {
    flex-direction: unset;
    justify-content: space-between;
  }
  button {
    font-weight: typography.$bold;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 10px;
      display: block;
      height: 45px;
    }
  }
}
.button-wrap.disable {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.Inputerror {
  input {
    border-color: color.$red;
  }
}

.inputfields-first {
  display: flex;
  gap: 10px;
}

@media all and (max-width: 767px) {
  .createAccountModal {
    .modalDialog,
    .modalDialogWrapper {
      max-width: 100%;
      margin: 20px;
    }
  }

  .inputfields-first {
    flex-direction: column;
  }

  .button-wrapper {
    flex-direction: column;
  }

  .rightSection {
    text-align: center;
    .rewards-heading {
      display: none;
    }
    ul {
      display: flex;
      justify-content: center;
      gap: 20px;
      li {
        padding: 0;
        margin: 0;
        position: relative;
        &:last-child {
          &::after {
            content: none;
          }
        }
        &::after {
          content: '';
          position: absolute;
          right: -10px;
          top: 0;
          height: 100%;
          width: 2px;
          background: color.$blue-royal;
        }
        .icon::after,
        .icon::before {
          content: none;
        }
        .subtitle {
          display: none;
        }
      }
    }

    .signInBottom {
      display: none;
    }
  }
}
.radioWrapper {
  .radio-button-wrap {
    margin-bottom: 15px;
  }
}
.radioWrapper-rewardEnable {
  .radio-button-wrap {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
    h6 {
      width: 100%;
      padding-left: 8px;
      padding-right: 7px;
    }
    .create-ac-option {
      margin-right: 22px;
      margin-left: 0px;
    }
    .error-msg {
      width: 100%;
    }
  }
}

.typically-options-for {
  font-weight: typography.$bold;
}
.seePrivacyPolicy {
  font-size: 13px;
  color: #007daa;
  &:hover {
    text-decoration: underline;
  }
}
.textAlignRight {
  justify-content: space-between;
  align-items: flex-end;
}

.gsaUserText {
  span {
    font-size: 13px;
  }
}
.scroll-area {
  margin: 0 -17px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 180px);
  padding: 8px 20px;
  @include breakpoint('medium') {
    margin: 0;
    overflow: inherit;
    max-height: 100%;
    padding: 0;
  }

  label {
    margin-bottom: 5px;
  }

  input {
    margin-bottom: 15px;
  }
}

.sign-in-text {
  cursor: pointer;
  color: color.$primary-color;
  &.duplicate {
    margin-left: 3px;
  }
  &:hover {
    text-decoration: underline;
  }
}

.zip-code-wrapper {
  input {
    margin: 0 0 15px;
  }
}

.password-tooltip-close-icon {
  background: url(/assets/images/typeahead-x.png) no-repeat right top;
  height: 18px;
  width: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.password-tooltip-text {
  padding-right: 28px;
  display: inline-block;
}

.strengthBar {
  height: 20px;
  width: 100%;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  margin: 10px 0;
  position: relative;
}

.strengthBarSeparator {
  position: absolute;
  left: 33%;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #d8d8d8;
  &.second {
    left: 66%;
  }
}

.passwordConditionsWrapperCreate {
  transition: all 0.4s ease-in-out;
}

.slide-in {
  transform: translateY(0);
  opacity: 1;
}

.slide-out {
  transform: translateY(-100%);
  opacity: 0;
}

.strength-title {
  font-size: 14px;
  font-weight: typography.$bold;
}

.password-conditions-text {
  padding-top: 1px;
}

.leftSection {
  .signInBottom {
    position: static;
    display: block;
    @include breakpoint('medium') {
      display: none;
    }
  }
}
